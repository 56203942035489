import { ElectionDocumentInfo } from "@/services/Model/Election/ElectionDocumentInfo";
import { ParishInfo } from "@/services/Model/Election/ParishInfo";
import lodash from "lodash";

export class ElectionMaterialResolver {
    public static readonly PARISHWHITELISTCOLLECTION: Array<number> = [7028, 7051, 7055, 7083, 7089, 7556, 7563, 7570, 7136, 7146, 7400, 7455, 9103, 7172, 7311, 7332, 7313, 7267, 7233, 7361, 7496, 7335, 7218, 7310, 7317, 7287, 7336, 9246, 7357, 7495, 7312, 7355, 7525, 7286, 7524, 7548, 7219, 7532, 7360, 7527, 7669, 7613, 7614, 7654, 7658, 7668, 9321, 9343, 7849, 7882, 9139, 9254, 8336, 8455, 8694, 8724, 8725, 8727, 8732, 9076, 9110, 8353, 8272, 8282, 8286, 8525, 8528, 8533, 8558, 8562, 8575, 8590, 8598, 8600, 8601, 8614, 8791, 8802, 8804, 8818, 8839, 9091, 8863, 8555, 9326, 8072, 8042, 8043, 8045, 8050, 8079, 8148, 8203, 8133, 8211, 8212, 8233, 8236, 8237, 9316, 9338, 8769, 8788, 8869, 8870, 8875, 8905, 8913, 8918, 8919, 8920, 9032, 9033, 9047, 9058, 8976, 7917, 7948, 7949, 7951, 7962, 8972, 8992, 8997, 9005, 9013, 9026, 9023, 7712, 7768, 7769, 7817, 7821, 7809, 7720, 7512, 7207, 7164, 7209, 7395, 8733, 9339, 8138, 8230, 7828 ];

    public static ElectionMaterialAvailable = (electionDocument: ElectionDocumentInfo): boolean => {
        // Arrange
        if(!electionDocument || !electionDocument.parishCollection)
            return false;
        // Act
        var parishInWhitlist = lodash.some(electionDocument.parishCollection, (parish: ParishInfo) => lodash.includes(ElectionMaterialResolver.PARISHWHITELISTCOLLECTION, parish.parishId));

        return electionDocument.electionYear === 2024 || parishInWhitlist;
    }

    public static ElectionMatchElectionYear = (electionDocument: ElectionDocumentInfo): boolean => {
        return electionDocument.electionYear === 2024;
    }

    public static GetParishWhitelisted = (electionDocument: ElectionDocumentInfo): ParishInfo[] => {
        return lodash.filter(electionDocument.parishCollection, (parish: ParishInfo) => lodash.includes(ElectionMaterialResolver.PARISHWHITELISTCOLLECTION, parish.parishId));
    }

    public static ParishIdIsWhiteListed = (parishId: number): boolean => {
        return lodash.includes(ElectionMaterialResolver.PARISHWHITELISTCOLLECTION, parishId);
    }
}